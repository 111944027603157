// =========================================================
// * Vuetify Material Dashboard PRO - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/vue-world-map'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import Print from 'vue-print-nb'
import GlobalConfig, { loadConfig } from './GlobalSettings'
import './registerServiceWorker'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

Vue.use(Print)

Vue.filter('priceDisplay',
  function (price) {
    const res = parseFloat(price).toFixed(2) === '-0.00' ? '0.00' : parseFloat(price).toFixed(2)
    return res.replace('.', ',') + ' €'
  },
)
const duration = require('dayjs/plugin/duration')
dayjs.extend(duration)
dayjs.extend(relativeTime)
Vue.filter('timeStampDisplay', function (timeStamp) {
  return timeStamp?.split(' ')[1]
})
Vue.filter('beautifulTime', function (timeStamp) {
  return dayjs(timeStamp, 'YYYY-MM-DD HH:mm:ss').format('DD.MM/HH:mm')
})

Vue.filter('timeToNow', function (time) {
  return dayjs(time).fromNow()
})

export function uuidv4 () {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16))
}

async function initial () {
  await loadConfig({
    Base: 'ik0001.ngrok.aaden.io',
  })

  store.commit('SAVE_CURRENT_DEVICE', parseInt(GlobalConfig.DeviceId))

  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
  }).$mount('#app')
}

initial()
